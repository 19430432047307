import React, { useState, useEffect, Suspense, lazy } from "react";
import { useHistory } from "react-router";
import Header from "../../header/Header";
import SideMenu from "../../NewSideMenu/NewSideMenu";
import { Container, Row, Input, Col } from "reactstrap";
import "./HrTickets.css";
import MaterialIcon from "material-icons-react";
import TicketDetail from "./TicketDetail";
import toast, { Toaster } from 'react-hot-toast'; 


export default function HrTicketDetails(props) {
  const [opencount, setOpenCount] = useState(0);
  const [showMenu, setshowMenu] = useState(true);
  const [myId, setmyId] = useState(0);
  const [myPrivileges, setMyPrivileges] = useState([]);
  const [month, setMonth] = useState(0);
  const [userType, setUserType] = useState(1);
  const [viewMember, setViewMember] = useState(0);
  const history = useHistory();


  useEffect(() => {
    checkUserPrivilege();
  }, []);

  const checkUserPrivilege = () => {
    if (localStorage.getItem("user") !== null) {
      var login = JSON.parse(localStorage.getItem("user"));
      setmyId(login.uid);
      setMyPrivileges(login.privileges);
      if (login.privileges.includes("access_all_reports")) {
        setUserType(1);
      } else if (login.privileges.includes("access_team_reports")) {
        setUserType(2);
      } else {
        setUserType(3);
      }
    }
  };
  
  function toggleSidebar() {
    setshowMenu(!showMenu);
  }

  function hideMenu() {
    setshowMenu(!showMenu);
  }
  
  return (
    <div className="fullwidth_mobilemenu">
        <Container fluid className={(myPrivileges.includes('i_am_designer')? 'top-bar-active tickets-lists': 'top-bar-inactive tickets-lists')}>
            <Row>
                <Toaster position="top-right" containerClassName="notifier"
                      toastOptions={{
                        className: '',
                        duration: 5000,
                        style: {
                          background: '#363636',
                          color: '#fff',
                        },}}
                  />
                <div className="theme_header">
                <Header
                    ncount={opencount}
                    {...props}
                    className="tick-timer-header"
                    quickactionblock={false}
                />
                    <div className="SideNavbar">
                    <SideMenu
                        activeitem="report"
                        showmenu={showMenu}
                        hidemenu={hideMenu}
                    />
                    </div>
                </div>
                <div className="themeContentBody themefullwidthContentBoday theme_active_header">
                    <TicketDetail
                    myId={myId}
                    />
                </div>
          </Row>
        </Container>
    </div>
    );
}