import React, { Component, Fragment } from 'react';
import './tickets.css'
import Header from '../header/Header';
import {urls} from "../../services/UrlList";
import {Container, Row, Col, Card, Input, Button, Label, InputGroup, FormGroup, CardTitle} from 'reactstrap';
import {isMobile} from 'react-device-detect';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import axios from 'axios';
import ReactQuill from 'react-quill'; 
import 'react-quill/dist/quill.snow.css';
import TicketComment from "./TicketComments/TicketComents";
import moment from "moment";
import ReactTags from 'react-tag-autocomplete'
import BasecampIcon from '../svgicon/BasecampIconURL';
import TrelloIcon from '../svgicon/TrelloIconURL';
import ConfirmAction from "../admincomment/ConfirmAction";
import Linkify from 'linkifyjs/react';
import ReactHtmlParser from 'react-html-parser';
import StarRatings from 'react-star-ratings';
import HealthIcon from '../../images/health_icon.png';
import FullUserPicker from "./FullUserPicker";
import { SRLWrapper } from "simple-react-lightbox";
import SimpleReactLightbox from 'simple-react-lightbox';
import AttachmentIcon from "../svgicon/AttachmentIcon";
import DateTimePicker from 'react-datetime-picker';
import AvatarImg from '../../images/useravatr.png';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import SideMenu from "../NewSideMenu/NewSideMenu";
import QuillMention from 'quill-mention'
import 'quill-mention/dist/quill.mention.min.css';
import CalenderIcon from '../../images/date-icon.png'
import ClearIcon from '../../images/clear.png'
import MaterialIcon from "material-icons-react";
import { Scrollbars } from 'react-custom-scrollbars';
import toast, { Toaster } from 'react-hot-toast';
import TicketFileUpload from './TicketFileUpload';
import TicketLightboxPop from './TicketLightboxPop';

var atValues = [
  { id: 1, value: 'Fredrik Sundqvist' },
  { id: 2, value: 'Patrik Sjölin' }
];

var selectedMentions=[];

export default class TicketDetails extends Component {
  fileObj = []; 
  fileArray = [];
  fileNamesArray = [];
  constructor(props) {
    super(props);
    this.state = {
      randomNum: Math.floor(Math.random() * 160),
      myPrivileges: [],
      myId: "",
      myBaseId: "",
      myName: "",
      myTimezone:'',
      showProgress: true,
      ticketDate: new Date(),
      minDate: new Date(),
      files: [],
      topics:[],
      status:[],
      tags:[],
      setTags:[],
      issueTypes:[],
      existtags:'',
      initialLoader:true,
      healthLoader:false,
      statusLoader:false,
      commentAddingLoader:false,
      reminderAddingProgress:false,
      availableAssignees:[],
      allPms:[],
      allPls:[],
      uploadingFiles:false,
      showDeletionDialogue:false,
      relatedArray:[22, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 46, 47, 48, 49,50, 51,53,54,55,56],
      myId:'',
      ticketOwner:'',
      ticketDetails:'',
      dueDate:'',
      ticketOldDetails:[],
      ticketComments:[],
      ticketAssignees:[],
      tassignees:[],
      tparticipants:[],
      imgagePreviews:[],
      commentAttachments:[],
      commentAttachmentsFiles:[],
      commentAttachmentsChange: false,
      ticketAttachmentsChange:false,
      ticketImages:'',
      folderName:'',
      ticketStatus:'',
      ticketStatusText:'',
      ticketTopicSelected:'',
      ticketIssueSelected:'',
      ticketIssueSelectedId:'',
      responsibelSelected:'',
      comments:'',
      statusBlocker:0,
      relatedError:false,
      assignedError:false,
      fileTypeError:false,
      detailError:false,
      reminderTimeError:false,
      iCanEdit:false,
      healthData:[],
      offset:-1,
      selectedResponsives:[{id: 165, name: "Abhilash K"}, {id: 195, name: "Abraham David A"}],
      showHealthData:false,
      editTicketFlag: false,
      editTicketId:'',
      editTicketDescription:'',
      editTicketCommentFlag:false,
      editTicketCommentId:'',
      editTicketComment:'',
      detaileditAllowed:false,
      ticketratedHealth:0,
      reminderTime:'',
      pending_reminders:0,
      adminShowReminders:false,
      remindGroup:1,
      remindMembers:[],
      remindMembersSelected:[],
      mentionsSelected:[],
      accessMembers:[],
      specialTicket:false,
      reminderDeletionProgress:false,
      reminderDeletionActive:false,
      pendingRemindersDeleted:'Deleted Pending Reminders',
      showMenu:true,
      issueUpdateNeeded:false,
      options: {
        attributes: null,
        className: 'linkified',
        defaultProtocol: 'http',
        events: null,
        format: function (value, type) {
          return value;
        },
        formatHref: function (href, type) {
          return href;
        },
        ignoreTags: [],
        nl2br: false,
        tagName: 'a',
        target: {
          url: '_blank'
        },
        validate: true
      }
    };
    this.handleChangeEditor = this.handleChangeEditor.bind(this);
    this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this);
    this.reactTags = React.createRef();
  }

  isBottom(el){
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }

  mentionModule = {
    allowedChars: /^[A-Za-z\s]*$/,
    mentionDenotationChars: ["@"],
    mentionContainerClass: 'ql-mention-list-container-bottom',
    listItemClass:'ql-mention-list-item',
    mentionListClass:'ql-mention-list',
    minChars: 2,
    source: function (searchTerm, renderList, mentionChar) {
      let values;

      if (mentionChar === "@") {
        values = atValues;
      }

      if(searchTerm.length >0) {
        const matches = [];
        for (var i = 0; i < values.length; i++)
          if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) matches.push(values[i]);
        renderList(matches, searchTerm);
      }
    },
    onSelect: function (item, insertItem) {
      selectedMentions.push(item['id']);
      insertItem(item);
    }
  }

  componentDidMount = () => {
    document.title = "HUB - Ticket System";
    this.checkUserPrivilege();
    this.getTicketAssets();
    const _this = this;
    setTimeout(() => {
      _this.getTicket();
    }, 200);
  };

  componentDidUpdate(prevState) {
    if (prevState.fileTypeError !== this.state.fileTypeError && this.state.fileTypeError === true) {
      const _this = this;
      setTimeout(function() {
        _this.setState({ fileTypeError: false });
      }, 5000);
    }

    if (prevState.reminderDeletionActive !== this.state.reminderDeletionActive && this.state.reminderDeletionActive === true) {
      const _this = this;
      setTimeout(function() {
        _this.setState({ reminderDeletionActive: false });
      }, 5000);
    }
  }

  componentWillUnmount()
  {
    this.getTicketAssets();
    this.getTicket();
    console.log(selectedMentions+"Before clear");
    selectedMentions=[];
    console.log(selectedMentions+"mention cleared");

  }

  checkUserPrivilege = () => {
    if (localStorage.getItem("user") !== null) {
      var login = JSON.parse(localStorage.getItem("user"));
      this.setState({
        myPrivileges: login.privileges,
        myId: login.uid,
        myBaseId: login.basecampid,
        myName: login.name,
        myTimezone:login.my_timezone
      });
    }
  };

  renderContentDevice = () => {
    if (isMobile && !this.state.myPrivileges.includes('mobile_access')) {
        this.setState({isMobileView:1});
    } 
    if (isMobile) {
      this.setState({showMenu:false});
    }  
  };

  onDeleteAssignees (i) {
    if(this.state.iCanEdit)
    {
      const tassignees = this.state.tassignees.slice(0)
      tassignees.splice(i, 1)
      this.setState({ tassignees });
      this.updateTicketAssignees(tassignees);
      this.setState({
        remindMembers: this.state.tassignees.concat(this.state.tparticipants)
      })
    }
  }
 
  onAdditionAssignees (tag) {
    if(this.state.iCanEdit)
    {
      console.log("hello");
      const tassignees = this.state.tassignees.slice(0)
      tassignees.splice(0, 1)
      this.setState({ tassignees });
      this.updateTicketAssignees(tassignees);

      const _this = this;
      setTimeout(() => {
        const tassignees1 = [].concat(_this.state.tassignees, tag)
        _this.setState({ tassignees: tassignees1 });
        _this.addTicketAssignee(tag);

        _this.setState({
          remindMembers: tassignees1.concat(_this.state.tparticipants)
        })

      }, 200);
    }
    
  }

  onDeleteParticipants = async(i) => {
    if(this.state.iCanEdit)
    {
      const tparticipants = this.state.tparticipants.slice(0)
      tparticipants.splice(i, 1)
      this.setState({ tparticipants });
      await this.updateTicketParticipants(tparticipants);
    }
    this.setState({
      remindMembers: this.state.tassignees.concat(this.state.tparticipants)
    })
  }
 
  onAdditionParticipants = async (tag) => {
    if(this.state.iCanEdit)
    {
      const tparticipants = [].concat(this.state.tparticipants, tag)
      this.setState({ tparticipants });
      this.addTicketParticipants(tag);

      const _this = this;
      setTimeout(() => {
        _this.setState({
          remindMembers: tparticipants.concat(_this.state.tassignees)
        })
      }, 200);

    }
  }

  onAdditionRemindees (tag) {
    if(this.state.iCanEdit)
    {
      const remindMembersSelected = [].concat(this.state.remindMembersSelected, tag)
      this.setState({ remindMembersSelected });
    }
  }

  onDeleteRemindees (i) {
    if(this.state.iCanEdit)
    {
      const remindMembersSelected = this.state.remindMembersSelected.slice(0)
      remindMembersSelected.splice(i, 1)
      this.setState({ remindMembersSelected });
    }
  }

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");
          fetch(urls.logmeout)
            .then(response => response.json())
            .then(response => {
              localStorage.clear();
              this.props.history.push("/");
            })
            .catch(error => {
              localStorage.clear();
              this.props.history.push("/");
            });
       
      });
  };

  getTicketAssets = ()=>
  {
      fetch(urls.getTicketAssets,{
          headers:{
            'Access-Control-Allow-Origin':'*',
            'Content-Type' : 'application/json'
          }
      })
      .then(response => response.json())
      .then(response=>
      {
        this.setState({
          topics: response['topics'],
          status: response['status'],
          tags: response['tags'],
          issueTypes: response['issue_types'],
          availableAssignees: response['available-assignees'],
          allPms: response['available-pms'],
          allPls: response['available-pmstls'],
          folderName: response['cfolder']
        })
        atValues = response['available-mentions'];
      })
      .catch((error)=>
      {

      })
  }

  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }
 
  handleChangeEditor(value) {
    this.setState({ comments: value })
  }

  handleChangeTicketEditor =(value) => {
    this.setState({ editTicketDescription: value })
  }

uploadMultipleFiles(e) {
    this.setState({
      uploadingFiles:true
    })
    this.fileObj.push(e.target.files)
    for (let i = 0; i < this.fileObj[0].length; i++)
    {
      var ftpe = this.fileObj[0][i].type;
      var fsize = this.fileObj[0][i].size;
      if((ftpe == 'image/jpeg' || ftpe == 'image/jpeg' || ftpe == 'image/png' ||  
      ftpe == 'image/gif' || ftpe == 'application/pdf') &&  fsize < 2050000)
      {
        const data = new FormData();
        data.append('file', this.fileObj[0][i]);
        data.append('filename', this.fileObj[0][i].name);
        data.append('user_id', this.state.myId);
        axios.post(urls.uploadTicketCommentFiles, data, { 
        // receive two    parameter endpoint url ,form data
        })
        .then(res => { 
            this.setState({
              uploadingFiles:false
            })
        })
        this.fileNamesArray.push(urls.storageUrl+"storage/tickets/comments/"+this.state.folderName+"-"+this.state.myId+"/"+this.fileObj[0][i].name);
        this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]));
      }else{
        this.setState({fileTypeError:true});
        break;
      }
    }
    this.fileObj = [];
    this.setState({ files: this.fileNamesArray, imgagePreviews:this.fileArray })
}
handleChange = ({ target }) => {
  this.setState({ [target.name]: target.value });
};

handleClick = e => {
    var array = [...this.state.tassigneesString];
    if (e.target.checked) {
      array.push(e.target.value);
      this.setState({
        assignedError:false
      });
    } else {
      if(array.length<=1)
      {
        this.setState({
          assignedError:true
        });
      }else
      {
        var array = [...this.state.tassigneesString]; // make a separate copy of the array
        var index = array.indexOf(e.target.value);
        if (index !== -1) {
          array.splice(index, 1);
        }
      }

    }
    this.setState({ tassigneesString: array, tassignees:array.map(Number)  });
    const _this = this;
    setTimeout(() => {
      _this.showAssigneeNames();
      _this.updateTicketAssignees();
    }, 100);
  };

  updateTicketAssignees = (assignees) =>
  {
      let opts ={
        ticket_id: this.props.match.params.id,
        assginees:assignees
      }
      fetch(urls.updateTicketAssignees,{
        headers:{
          'Access-Control-Allow-origin':'*',
          'Content-Type':'application/json'
        },
        method: "post",
            body: JSON.stringify(opts)
      })
      .then(response => response.json())
      .then(response =>
      {   
      })
      .catch(error =>
      {
        
      })

  }
  
 stripslashes = (str) => {
    str = str.replace(/\\'/g, '\'');
    str = str.replace(/\\"/g, '"');
    str = str.replace(/\\0/g, '\0');
    str = str.replace(/\\\\/g, '\\');
    return str;
}


  updateTicketParticipants = async (participants) =>
  {
      let opts ={
        ticket_id: this.props.match.params.id,
        participants:participants
      }
      fetch(urls.updateTicketParticipant,{
        headers:{
          'Access-Control-Allow-origin':'*',
          'Content-Type':'application/json'
        },
        method: "post",
            body: JSON.stringify(opts)
      })
      .then(response => response.json())
      .then(response =>
      {   
      })
      .catch(error =>
      {
        
      })

  }


  addTicketParticipants = (participant) =>
  {
      let opts ={
        ticket_id: this.props.match.params.id,
        participant:participant.id,
        ticket_subject: this.state.ticketOldDetails[0].subject
      }
      fetch(urls.addTicketParticipant,{
        headers:{
          'Access-Control-Allow-origin':'*',
          'Content-Type':'application/json'
        },
        method: "post",
            body: JSON.stringify(opts)
      })
      .then(response => response.json())
      .then(response =>
      {   
      })
      .catch(error =>
      {
        
      })
  }

  addTicketAssignee = (assignee) =>
  {
      let opts ={
        ticket_id: this.props.match.params.id,
        assignee:assignee.id,
        ticket_subject: this.state.ticketOldDetails[0].subject,
        username: this.state.myName
      }
      fetch(urls.addTicketAssignee,{
        headers:{
          'Access-Control-Allow-origin':'*',
          'Content-Type':'application/json'
        },
        method: "post",
            body: JSON.stringify(opts)
      })
      .then(response => response.json())
      .then(response =>
      {   
      })
      .catch(error =>
      {
        
      })
  }

  showAssigneeNames = () =>
  {
    var tempassginees = '';
    let temp = this.state.tassigneesString.map(Number);
    this.state.availableAssignees.forEach(assignee => {
      if(temp.includes(assignee.id))
      {
        tempassginees += assignee.name +', ';
      }
    });
    this.setState({
      tassigneesnames: tempassginees.slice(0, -2)
    })
  }

  getTicket = () =>
  {
    let opts ={
      ticket_id: this.props.match.params.id,
      user_id:this.state.myId
    }
    fetch(urls.getTicket,{
			headers:{
				'Access-Control-Allow-origin':'*',
				'Content-Type':'application/json'
			},
			method: "post",
        	body: JSON.stringify(opts)
		})
		.then(response => response.json())
		.then(response =>
		{   
         
         this.setState({
          accessMembers: response.assigneesids.concat(response.participantsids),
          remindMembers: response.assignees.concat(response.participants),
          specialTicket: response.data[0].special_ticket === 1? true : false,
          ticketOwner: response.data[0].created_by,
          ticketCreatedOn: response.data[0].created_on,
          tassignees:response.assignees,
          tparticipants: response.participants,
          tassigneesString: response.assigneesstring
          });
          const _this = this;
          setTimeout(() => {
          _this.checkMyRole();
          _this.generateTags()
          }, 500);
          setTimeout(() => {
          _this.setState({
                ticketOldDetails:response.data,
                ticketratedHealth:response.health_rating,
                ticketImages: response.data[0].ticket_attachments!= null? response.data[0].ticket_attachments.split(',') : [],
                ticketComments:response.comments,
                ticketStatus:response.data[0].status,
                existtags:response.data[0].ticket_tags,
                ticketTopicSelected:response.data[0].ticket_topic_id,
                ticketIssueSelected: response.data[0].issue_type_id,
                responsibelSelected: response.data[0].issue_creators,
                selectedResponsives: response.data[0].issue_creator,
                dueDate: response.data[0].due_date != null ? new Date(response.data[0].due_date):'',
                ticketStatusText: response.data[0].ticstatus,
                statusBlocker: response.statusblocker,
                detaileditAllowed: response.edit_allowed,
                pending_reminders: response.pending_reminders,
                initialLoader:false,
                })
            }, 800);
            
		})
		.catch(error =>
		{
			this.setState({
        initialLoader:false
    })
		})
  }

  onTDuedateChange = (date) => 
  {
    this.setState({ dueDate: date });
    let opts ={
      ticket_id: this.props.match.params.id,
      due_date: date,
      user_id:this.state.myId
    };
    console.log(opts);
    fetch(urls.updateTicketDueDate,{
      headers:{
        'Access-Control-Allow-origin':'*',
        'Content-Type':'application/json'
      },
      method: "post",
          body: JSON.stringify(opts)
    })
    .then(response => response.json())
    .then(response =>
    {   
      console.log(response);
    })
    .catch(error =>
    {
      console.log(error);
    })
  }

  generateTags = () =>
  {
    let temp=[];  
    if(this.state.existtags != null && this.state.existtags != '')
    {
    this.state.tags.forEach(p1 => {
        if(this.state.existtags.includes(p1.name))
        {
            temp.push(p1);
        }
      });
    }
    this.setState({setTags:temp});
  }

  filterMentions = () =>
  {
    var remmembers = [];
    let temp=[];
    let matrix = this.state.remindMembers;
    for(var i=0; i<matrix.length; i++){
      remmembers.push(String(matrix[i]['id']));
    }
    console.log(selectedMentions);
    selectedMentions.forEach(member => {
      if(!remmembers.includes(member))
      {
        temp.push(member);
      }
    });
    return temp;
  }
  createTicketComment = () =>
  {
    this.setState({
      comments:false
    });
   if(this.state.comments === '')
    {
        this.setState({detailError:true});
        return false;
    }
    this.setState({commentAddingLoader:true});
    var mentions = this.filterMentions();
    let opts ={
        ticket_id: this.props.match.params.id,
        ticket_subject: this.state.ticketOldDetails[0].subject,
        user_id:this.state.myId,
        username: this.state.myName,
        comments: this.state.comments,
        comment_attachments: this.state.commentAttachments,    //this.state.files,
        mentions:selectedMentions,
        mentionsnew: mentions
    }

    fetch(urls.newHubTicketComment,{
			headers:{
				'Access-Control-Allow-origin':'*',
				'Content-Type':'application/json'
			},
			method: "post",
        	body: JSON.stringify(opts)
		})
		.then(response => response.json())
		.then(response =>
		{
        this.setState({
            files:[],
            commentAttachments:[],
            commentAttachmentsFiles:[],
            comments:'',
            commentAddingLoader:false,
        })
        this.getTicket();
        this.fileNamesArray = [];
        this.fileArray = [];
		})
		.catch(error =>
		{
			this.setState({
        commentAddingLoader:false,
    })
		})
  }

  showTicketCreation =() =>
  {
    this.props.history.push("/new-ticket"+"?uuid="+this.state.myId);
  }
  showDashboard =() =>
  {
    this.props.history.push("/home"+"?uuid="+this.state.myId);
  }

  showTicketList = () =>
  {
    this.props.history.push("/tickets"+"?uuid="+this.state.myId);
  }

  updateTicketStatus = (e) =>
  {
      let opts ={
          ticket_id: this.props.match.params.id,
          status:e.target.value,
          user_id:this.state.myId
        }
        this.setState({
          ticketStatus:e.target.value,
          statusLoader:true,
        })
        fetch(urls.updateTicketStatus,{
          headers:{
            'Access-Control-Allow-origin':'*',
            'Content-Type':'application/json'
          },
          method: "post",
              body: JSON.stringify(opts)
        })
        .then(response => response.json())
        .then(response =>
        {   
          this.showATicketStatus();
          this.setState({statusLoader:false})
          if(this.state.ticketStatus == 8)
          {
            this.props.history.push("/tickets"+"?uuid="+this.state.myId);
          }
        })
        .catch(error =>
        {
          this.setState({statusLoader:false})
        })
  }

  updateTicketTopic = (e) =>
  {
    if(e.target.value == '')
    {
        this.setState({relatedError:true});
    }else
    {
      console.log(e.target.value);
      this.setState({relatedError:false, ticketTopicSelected:e.target.value});
      let opts ={
        ticket_id: this.props.match.params.id,
        topic_id:e.target.value,
        user_id:this.state.myId
      }
      this.setState({
        relatedTo:e.target.value
      })
      fetch(urls.updateTicketTopic,{
        headers:{
          'Access-Control-Allow-origin':'*',
          'Content-Type':'application/json'
        },
        method: "post",
            body: JSON.stringify(opts)
      })
      .then(response => response.json())
      .then(response =>
      {   
      })
      .catch(error =>
      {
      })
    }
  }

  // updateTicketIssueType = (e) =>
  // {
  //     let opts ={
  //       ticket_id: this.props.match.params.id,
  //       issue_type_id:e.target.value,
  //     }
  //     this.setState({
  //       ticketIssueSelected:e.target.value
  //     })
  //     fetch(urls.updateTicketIssueType,{
  //       headers:{
  //         'Access-Control-Allow-origin':'*',
  //         'Content-Type':'application/json'
  //       },
  //       method: "post",
  //           body: JSON.stringify(opts)
  //     })
  //     .then(response => response.json())
  //     .then(response =>
  //     {   
  //     })
  //     .catch(error =>
  //     {
  //     })
  // }

  showATicketStatus = () =>
  {
    this.state.status.forEach(tstatus => {
      if(tstatus.id == this.state.ticketStatus)
      {
        this.setState({
          ticketStatusText: tstatus.status
        })
      }
    });
  }

  checkMyRole = () =>
  {
    console.log("memeber access",this.state.accessMembers);
    if(this.state.specialTicket && !this.state.myPrivileges.includes('co_founder_only') && !this.state.accessMembers.includes(this.state.myId) )
    {
      this.props.history.push("/tickets"+"?uuid="+this.state.myId);
    }else if((this.state.myId !== this.state.ticketOwner && !this.state.accessMembers.includes(this.state.myId)) && (!this.state.myPrivileges.includes('all_tickets') && !this.state.myPrivileges.includes('unassigned_ticket_access') ))
    {
      this.props.history.push("/tickets"+"?uuid="+this.state.myId);
    }else
    {
      console.log('good to go');
    }

    if(this.state.ticketOwner === this.state.myId || this.state.myPrivileges.includes('all_tickets'))
    {
      this.setState({
        iCanEdit:true,
      })
    }else if(this.state.tassignees.length === 0)
    {
      this.setState({
        iCanEdit:true,
      })
    }else
    {
      let temp1 = this.state.tassignees;
      temp1.forEach(p1 =>{
        if(p1.id == this.state.myId)
        {
            this.setState({
              iCanEdit:true,
            })
        }
      })
    }
  }

  triggerDeletion = () =>
	{
		this.setState(
			{
				showDeletionDialogue: true,
			});
  }
  
  closeDialogue = () => {
		this.setState({
		  showDeletionDialogue: false
		});
	  };

	handleDeletionConfirm = () => {
	this.setState({ showDeletionDialogue: !this.state.showDeletionDialogue });
	const _this = this;
	setTimeout(function() {
		_this.deleteTicket();
	}, 1000);
  };
  
  deleteTicket = () =>
  {
    this.setState({initialLoader:true});
    let opts ={
      ticket_id: this.props.match.params.id,
      user_id: this.state.myId
    }
    fetch(urls.deleteTicket,{
      headers:{
        'Access-Control-Allow-origin':'*',
        'Content-Type':'application/json'
      },
      method: "post",
          body: JSON.stringify(opts)
    })
    .then(response => response.json())
    .then(response =>
    {   
      this.props.history.push("/tickets"+"?uuid="+this.state.myId);
    })
    .catch(error =>
    {
      this.setState({initialLoader:false});
    })
  }

  removePreviewImg = (url) =>
  {
    var index = this.fileArray.indexOf(url); // 1
    this.fileArray.splice(index, 1);
    this.fileNamesArray.splice(index, 1);
    this.setState({imgagePreviews: this.fileArray, files: this.fileNamesArray});
  }

  onDeleteTag (i) {
    const setTags = this.state.setTags.slice(0)
    setTags.splice(i, 1)
    this.setState({ setTags });
    this.updateTicketTags(setTags);
  }
 
  onAdditionTag (tag) {
    const setTags = [].concat(this.state.setTags, tag)
    this.setState({ setTags })
    this.updateTicketTags(setTags);
  }

  onAdditionParticipantAllPms = async () =>{
    const tparticipants = [].concat(this.state.tparticipants, this.state.allPms)
   
    tparticipants.forEach( async (tag)=>
    {
      await this.onAdditionParticipants(tag);
    })
    this.setState({ tparticipants});
  }

  onAdditionParticipantAllPls = async () =>{
    const tparticipants = [].concat(this.state.tparticipants, this.state.allPls)
   
    tparticipants.forEach( async (tag)=>
    {
      await  this.onAdditionParticipants(tag);
    })
    this.setState({ tparticipants});
  }

  updateTicketTags = (mtags) =>
  {
      let opts ={
        ticket_id: this.props.match.params.id,
        tags:mtags
      }
      fetch(urls.updateTags,{
        headers:{
          'Access-Control-Allow-origin':'*',
          'Content-Type':'application/json'
        },
        method: "post",
            body: JSON.stringify(opts)
      })
      .then(response => response.json())
      .then(response =>
      {   
      })
      .catch(error =>
      {
        
      })

  }

  getClientHealthData = (client) =>
  {
      let opts ={
        client_name: client,
        offset:this.state.offset+1,
      }
      this.setState({
        showHealthData:true,
        healthLoader:true,
      })
      fetch(urls.postHealthReportData,{
        headers:{
          'Access-Control-Allow-origin':'*',
          'Content-Type':'application/json'
        },
        method: "post",
            body: JSON.stringify(opts)
      })
      .then(response => response.json())
      .then(response =>
      {   
        this.setState({
          healthData:response[0].healthdata,
          healthLoader:false,
        })
      })
      .catch(error =>
      {
        this.setState({
          healthLoader:false,
        })
      })

  }
  
  closeHealth = ()=>
  {
    this.setState({
      showHealthData:false,
      offset:-1
    })
  }
  
  // onPeopleChange = (userid) =>
  // {
      
  //   let opts ={
  //       ticket_id: this.props.match.params.id,
  //       responsibelSelectedId:userid,
  //     }
      
  //     this.setState({
  //       selectedResponsives:userid
  //     })
  //     fetch(urls.updateTicketIssueResponsible,{
  //       headers:{
  //         'Access-Control-Allow-origin':'*',
  //         'Content-Type':'application/json'
  //       },
  //       method: "post",
  //           body: JSON.stringify(opts)
  //     })
  //     .then(response => response.json())
  //     .then(response =>
  //     {   
  //     })
  //     .catch(error =>
  //     {
  //     })
  // }



  updateTicketIssueType = (e) =>
  {
      this.setState({ticketIssueSelected:e.target.value, issueUpdateNeeded:true});
  }

  onPeopleChange = (userid) =>
  {
      this.setState({selectedResponsives:userid, issueUpdateNeeded:true});
  }


  updateTicketIssueNew = () =>
  {
    let opts ={
        ticket_id: this.props.match.params.id,
        issue_type_id: this.state.ticketIssueSelected,
        responsibelSelectedId: this.state.selectedResponsives,
        basecamp_url: this.state.ticketOldDetails[0].basecamp_link !== null ? this.state.ticketOldDetails[0].basecamp_link : '',
        ticket_details: this.state.ticketOldDetails[0].ticket_details
      }
      fetch(urls.updateTicketIssueNew,{
        headers:{
          'Access-Control-Allow-origin':'*',
          'Content-Type':'application/json'
        },
        method: "post",
            body: JSON.stringify(opts)
      })
      .then(response => response.json())
      .then(response =>
      {   
        this.setState({issueUpdateNeeded:false});
        toast('Issue type updated successfully');
      })
      .catch(error =>
      {
        toast('Something went wrong, please try again.');
      })
  }


  onEditTicket=()=>
  {
    this.setState({
      editTicketFlag:true,
      editTicketId: this.props.match.params.id,
      editTicketDescription: this.state.ticketOldDetails[0].ticket_details
    })
  }

  cancelTicketEdit =()=>
  {
    this.setState({
      editTicketFlag:false,
      editTicketId: '',
      editTicketDescription:''
    })
  }
  
  onUpdateTicketDetails = () =>
  {
      let opts ={
        ticket_id: this.props.match.params.id,
        ticket_details: this.state.editTicketDescription,
        creater_id: this.state.myId,
        ticket_attachments: this.state.ticketImages
      }
      fetch(urls.updateTicketDetails,{
        headers:{
          'Access-Control-Allow-origin':'*',
          'Content-Type':'application/json'
        },
        method: "post",
            body: JSON.stringify(opts)
      })
      .then(response => response.json())
      .then(response =>
      {   
        this.updateTicketDescription(this.state.editTicketDescription);
        this.setState({
          editTicketFlag:false,
          editTicketId: '',
          editTicketDescription:'',
        })
      })
      .catch(error =>
      { 
        this.setState({
          editTicketFlag:false,
          editTicketId: '',
          editTicketDescription:''
        })
      })
  }

  updateTicketDescription = (textdata)=>
  {
    let ticketdata = this.state.ticketOldDetails;
    let list = [];
    ticketdata.forEach(p1=>{
      p1.ticket_details = textdata;
      list.push(p1);
    })
    this.setState({ticketOldDetails:list});
  }

  showCommentEdit = (id, comment) =>
  {
    this.setState({
      editTicketCommentFlag:true,
      editTicketCommentId: id,
      editTicketComment: comment
    })
  }

  cancelCommentUpdate = () =>
  {
    this.setState({
      editTicketCommentFlag:false,
      editTicketCommentId: '',
      editTicketComment: ''
    })
  }

  updateTicketComment = () =>
  {
    this.setState({
      editTicketCommentFlag:false,
      editTicketCommentId: '',
      editTicketComment: ''
    })
    this.getTicket();
  }

  setReminderime = (e)=>
  {
    this.setState({
      reminderTime:e,
      reminderTimeError:false
    })
  }

  addReminder = () =>
  {
    if(this.state.reminderTime==='')
    {
      this.setState({
        reminderTimeError:true
      })
      return false;
    }else
    {
      this.setState({
        reminderAddingProgress:true
      })
      if(this.state.remindGroup == 1)
      {
        var opts ={
          ticket_id: this.props.match.params.id,
          reminderTime: this.state.reminderTime,
          user_id: this.state.myId,
          myname:this.state.myName,
          timezone:this.state.myTimezone
        }
        var furl = urls.addTicketReminder;
      }else if(this.state.remindGroup == 2)
      {
        var opts ={
          ticket_id: this.props.match.params.id,
          reminderTime: this.state.reminderTime,
          remindees: this.state.tassignees.concat(this.state.tparticipants)
        }
        var furl = urls.addTicketReminderSelected;
      }else
      {
        var opts ={
          ticket_id: this.props.match.params.id,
          reminderTime: this.state.reminderTime,
          remindees: this.state.remindMembersSelected
        }
        var furl = urls.addTicketReminderSelected;
      }
      fetch(furl,{
        headers:{
          'Access-Control-Allow-origin':'*',
          'Content-Type':'application/json'
        },
        method: "post",
            body: JSON.stringify(opts)
      })
      .then(response => response.json())
      .then(response =>
      {   
        if(response.comments.length>0)
        {
          this.setState({
            ticketComments:response.comments,
            reminderTime:'',
            reminderTimeError:false,
            reminderAddingProgress:false
          })
        }
      })
      .catch(error =>
      {
        this.setState({
         reminderAddingProgress:false
        })
      })
    }
  }

  setReminderTimeOptions = (e)=>
  {
    if(e.target.value>0)
    {
      this.setState({
        reminderTime:new moment(new Date()).add(e.target.value, 'h').toDate()
      })
    }
  }

  afterReminderUpdate =(comments)=>
  {
      this.setState({
        ticketComments:comments
      })
  }

  adminShowReminders = ()=>
  {
      this.setState({
        adminShowReminders:!this.state.adminShowReminders
      });
  }

  updateSelection = (e)=>
  {
    this.setState({
      remindGroup: e.target.value
    })
  }

  removePendingReminders = () =>
  {
    this.setState({
      reminderDeletionProgress:true
    })
    let opts={
      ticket_id: this.props.match.params.id,
      user_id: this.state.myId
    }

    fetch(urls.removePendingReminders,
      {
        headers:{
          'Access-Control-Allow-Origin':'*',
          'Content-Type': 'application/json'
        },
        'method':'post',
        'body': JSON.stringify(opts)
      })
      .then(response => response.json())
      .then(response =>
        {
          this.setState({
            reminderDeletionProgress:false,
            reminderDeletionActive:true,
            ticketComments:response.comments,
          })
        })
        .catch(error=>
        {
          this.setState({
            reminderDeletionProgress:false
          })
        })
  }

  issueUpdateCancel = ()=>
  {
    this.setState({
      ticketIssueSelected: this.state.ticketOldDetails[0].issue_type_id,
      selectedResponsives: this.state.ticketOldDetails[0].issue_creator,
      issueUpdateNeeded:false
    })
   
  }

  storeTicketFileNames = async (arrayname, item) => {
    let temp = [...this.state.ticketImages];
    let filename= urls.storageUrl+"storage"+item.path+"/"+item.file_name;
    await this.setState({ ticketImages: []});
    temp.push(filename);
    await this.setState({ ticketImages: temp});
  }


  deleteTicketFileNames = async (filename, arrayname) => {
    this.setState({ ticketAttachmentsChange: true });
    var assetupdate = [...this.state.ticketImages];
    this.setState({ ticketImages: []});
    
    var itemkey = assetupdate.map(function (item) { return item; }).indexOf(filename);
    assetupdate.splice(itemkey, 1);
    const _this = this;
    setTimeout(() => {
      _this.setState({ ticketImages: assetupdate,ticketAttachmentsChange: false });
    }, 500);
  }



  storeCommentFileNames = async (arrayname, item) => {
    let temp = [...this.state.commentAttachments];
    let filename= item.path+item.file_name;
    await this.setState({ commentAttachments: []});
    temp.push(filename);
    await this.setState({ commentAttachments: temp});
  }


  deleteCommentFileNames = async (filename, arrayname) => {
    this.setState({ commentAttachmentsChange: true });
    var assetupdate = [...this.state.commentAttachments];
    this.setState({ commentAttachments: []});
    
    var itemkey = assetupdate.map(function (item) { return item; }).indexOf(filename);
    assetupdate.splice(itemkey, 1);
    const _this = this;
    setTimeout(() => {
      _this.setState({ commentAttachments: assetupdate,commentAttachmentsChange: false });
    }, 500);
  }
  

  render() {
    return (
      <div className="fullwidth_mobilemenu">
      <Container fluid className={(this.state.myPrivileges.includes('i_am_designer')? 'top-bar-active tickets-lists': 'top-bar-inactive tickets-lists')}>
      <Row>
        <div className="theme_header">
        <Header {...this.props}  
        classname="tick-timer-header" 
        logout={this.signOut.bind(this)} 
        onmenuaction={this.toggleSidebar.bind(this)} 
        quickactionblock={true}/>
            <div className="SideNavbar">
           <SideMenu  
            activeitem="tickets" 
            showmenu={this.state.showMenu} 
            hidemenu={this.hideMenu.bind(this)}  /> 
          </div>
        </div>
        
        <div className="themeContentBody themefullwidthContentBoday theme_active_header">
        <Toaster position="top-right" containerClassName="notifier"
          toastOptions={{
            className: '',
            duration: 5000,
            style: {
              background: '#363636',
              color: '#fff',
            },
          }}
        />
       <LoadingWrapper isLoading={this.state.initialLoader}>
        {this.state.ticketOldDetails.length>0?
        (
          <Row>
            <Col sm={12} lg={4}>
              <Card className="ticket-sidebar-wrapper">
                 <div className="ticket_header_left">
                   <CardTitle tag="h1"><Button onClick={this.showTicketList} className="balck_btn"> <MaterialIcon icon="arrow_back"/></Button>Tickets</CardTitle>
                   <div className="health_img">
                        {this.state.ticketOldDetails[0].client_name != null &&
                          (
                            <Button className="health-img" onClick={this.getClientHealthData.bind(this.setState, this.state.ticketOldDetails[0].client_name)}><MaterialIcon icon="monitor_heart"/></Button>
                          )}
                          {this.state.ticketOldDetails[0].ticket_for_count !== null &&
                          (
                            <div className="warning-count">
                                {this.state.ticketOldDetails[0].ticket_for_count}
                            </div>
                          )}
                     
                   </div>
                 </div>
                 <div className="ticket_detail_left">
                    <div className="ticket-id-wrap">
                       <Label htmlFor="ticket-date-field" className="ticket-id-title">Ticket ID:</Label>
                       <div className="ticket-id-value">{this.state.ticketOldDetails[0].id}</div>
                    </div>
                    <div className="post_date">
                      <Label htmlFor="ticket-date-field" className="ticket-date-title">Date</Label>
                      <div className="text-data-view">{this.state.ticketOldDetails[0].created_date}</div>
                    </div> 
                    <div className="choose_related">
                        <FormGroup id="session-1">
                          <Label htmlFor="ticket-related-to" className="ticket-date-title">Related To</Label>
                          <Input disabled={!this.state.iCanEdit ? 'disabled': ''}  type="select" 
                          className="relates-area" onChange={this.updateTicketTopic}  name="relatedTo" >
                              <option value="">Select</option>
                              {this.state.topics.map((topic, index)=>
                              {
                                return(
                                  <option key={index} value={topic.id}
                                  selected={this.state.ticketTopicSelected == topic.id? 'selected':''}
                                  >{topic.topic_name}</option>
                                )
                              })}
                            </Input>
                          </FormGroup>
                          {this.state.relatedError &&
                          (
                            <div className="field-error-msg">Ticket should be linked with a topic</div>
                          )}
                    </div>
                  <div className="client_details">
                  {this.state.ticketOldDetails[0].client_name != null &&
                        (
                            <>
                                <div className="client_feedback">
                                {this.state.ticketOldDetails[0].incident_date!= null &&
                                (
                                  <div className="row-1 post_date ">
                                    <div>
                                      
                                      {this.state.ticketTopicSelected == 22 ?
                                      (
                                        <Label htmlFor="ticket-date-field" className="ticket-date-title">Feedback Date</Label>
                                      ):
                                      (
                                        <Label htmlFor="ticket-date-field" className="ticket-date-title">Incident Date</Label>
                                      )}

                                      <div className="text-data-view d-flex align-items-center justify-content-between">
                                        <span>
                                          {this.state.ticketOldDetails[0].incident_date}
                                        </span>
                                        {this.state.ticketratedHealth>0 &&
                                        (
                                          <span>
                                            <StarRatings
                                                rating={this.state.ticketratedHealth}
                                                starRatedColor={this.state.ticketratedHealth===5? 'red': 'orange'}
                                                numberOfStars={5}
                                                starSpacing="3px"
                                                starDimension="30px"
                                                starEmptyColor="#e4dddd"
                                                starHoverColor="orange"
                                                isSelectable={false}
                                            />
                                          </span>
                                        )}
                                        </div>
                                    </div>
                                  </div>  
                                )}
                              </div>
                                <div className="row-1 post_date">
                                  <Label htmlFor="ticket-date-field" className="ticket-date-title">Client Name</Label>
                                  <div className="text-data-view align-items-center  d-flex justify-content-between">
                                    <h3>{this.state.ticketOldDetails[0].client_name}</h3>
                                    <div className="d-flex">
                                      {this.state.ticketOldDetails[0].trello_link !== null &&
                                      (
                                        <span><TrelloIcon projecturl={this.state.ticketOldDetails[0].trello_link}/></span>
                                      )}
                                      {this.state.ticketOldDetails[0].basecamp_link !== null &&
                                      (
                                        <span><BasecampIcon projecturl={this.state.ticketOldDetails[0].basecamp_link}/></span>
                                      )}
                                    </div>
                                  </div>
                                  
                                  {this.state.ticketOldDetails[0].client_health_rating >0 &&
                                    (
                                      <StarRatings
                                      rating={this.state.ticketOldDetails[0].client_health_rating}
                                      starRatedColor="orange"
                                      numberOfStars={5}
                                      starSpacing="3px"
                                      starDimension="20px"
                                      starEmptyColor="#e4dddd"
                                      starHoverColor="orange"
                                      isSelectable={false}
                                      />
                                    )}
                                  
                                  {this.state.ticketOldDetails[0].trello_card!= null &&
                                  (
                                    <span className="single-line-data"><span>Trello Card: </span><a  target="_blank" href={this.state.ticketOldDetails[0].trello_card}>{this.state.ticketOldDetails[0].trello_card}</a></span>
                                  )}
                                  {this.state.ticketOldDetails[0].basecamp_task!= null &&
                                  (
                                    <span className="single-line-data"><span>KimpHub Task: </span><a  target="_blank" href={this.state.ticketOldDetails[0].basecamp_task}>{this.state.ticketOldDetails[0].basecamp_task}</a></span>
                                  )}
                                  {this.state.ticketOldDetails[0].designers!= null &&
                                  (
                                    <span className="single-line-data"><span>Designers Involved: </span>{this.state.ticketOldDetails[0].designers}</span>
                                  )}

                                </div> 
                            </>
                        )}
                  </div>
                  <div className={this.state.iCanEdit? "row-1 post_date assignees-field" : "row-1 post_date assignees-field cant-edit"}>
                    <Label htmlFor="ticket-date-field" className="ticket-date-title">Assigned To</Label>
                    <ReactTags
                    ref={this.reactTags}
                    tags={this.state.tassignees}
                    suggestions={this.state.availableAssignees}
                    onDelete={this.onDeleteAssignees.bind(this)}
                    onAddition={this.onAdditionAssignees.bind(this)}
                    placeholderText="Add only one" />
                  </div>
                  <div className={this.state.iCanEdit? "row-1 post_date assignees-field" : "row-1 post_date assignees-field cant-edit"}>
                          <Label htmlFor="ticket-date-field" className="ticket-date-title">Participants</Label>
                          <ReactTags
                          ref={this.reactTags}
                          tags={this.state.tparticipants}
                          suggestions={this.state.availableAssignees}
                          onDelete={this.onDeleteParticipants.bind(this)}
                          onAddition={this.onAdditionParticipants.bind(this)}
                          placeholderText="Add Participant" />

                        <Button title="Add all Project Leads to Participants list" className="btn btn-green add-multiple-members  btn btn-secondary" name="Add-all-pms" onClick={this.onAdditionParticipantAllPls}>All PL +</Button>
                        <Button title="Add all Project Managers to Participants list" className="btn btn-green add-multiple-members btn btn-secondary" name="Add-all-pms" onClick={this.onAdditionParticipantAllPms}>All PM +</Button>
                  </div>
                  <div className={this.state.iCanEdit? "row-1 post_date assignees-field" : "row-1 post_date assignees-field cant-edit"}>
                    <Label htmlFor="ticket-date-field" className="ticket-date-title">Labels</Label>
                    <ReactTags
                    ref={this.reactTags}
                    tags={this.state.setTags}
                    suggestions={this.state.tags}
                    onDelete={this.onDeleteTag.bind(this)}
                    onAddition={this.onAdditionTag.bind(this)}
                    placeholderText="Add Labels" />
                   </div>
                  <div className="issue_type">
                  {(this.state.relatedArray.includes(parseInt(this.state.ticketTopicSelected)) )&&
                      (
                      <>
                      <div className="row-1 post_date ticket_task_issue_type">
                        <Label htmlFor="ticket-date-field" className="ticket-date-title">Issue Type</Label>
                        {/* <Input type="select" id="issueType" 
                          className="relates-area" 
                          onChange={this.updateTicketIssueType.bind(this)}  name="ticketIssueSelected" >
                              <option value="">Select</option>
                              {this.state.issueTypes.map((topic, index)=>
                              {
                                return(
                                  <option key={index} value={topic.id}
                                  selected={this.state.ticketIssueSelected  == topic.id? 'selected':''}
                                  >{topic.issue_name}</option>
                                )
                              })}
                            </Input> */}

                            <div className="assign-group ulchekbox">
                              {this.state.issueTypes.map((topic, index)=>
                                {
                                  return(
                                    <div key={index} className="d-flex justify-content-between status-items rbstyle ch-item">
                                      <Input id={`issue-${topic.id}`} name="ticketIssueSelected" 
                                      value={topic.id} type="radio" 
                                      checked= {this.state.ticketIssueSelected == topic.id? 'checked': ''} 
                                      onChange={this.updateTicketIssueType}
                                      />
                                      <Label for={`issue-${topic.id}`} >{topic.issue_name}</Label>
                                  </div>
                                  )
                                })}
                            </div>
                      </div>
                      {this.state.ticketIssueSelected>0 &&
                      (
                        <div className="row-1 post_date responsible-person">
                            <Label htmlFor="ticket-date-field" className="ticket-date-title">Responsible Person</Label>
                            <FullUserPicker 
                                  myid={this.state.myId} 
                                  baseid={this.state.myBaseId} 
                                  teamid={0} 
                                  selected={this.state.selectedResponsives}
                                  usertype={this.state.showPeopleType} 
                                  onPeopleChange={this.onPeopleChange.bind(this)} />
                        </div>
                      )}
                      </>
                      )}

                      {this.state.issueUpdateNeeded &&
                      (
                        <div className='issue_update_buttons d-flex'>
                          <Button className='btn-grey' onClick={this.issueUpdateCancel}>Cancel</Button>
                          <Button className='btn-green' onClick={this.updateTicketIssueNew}>Update</Button>
                        </div>
                      )}
                      
                  </div>

                  <div className="row-1 post_date ticket-status-box">
                  <Label htmlFor="ticket-date-field" className="ticket-date-title">Status</Label>
                        <div className="ticket-text-status issue_dis_input  read-field  d-flex justify-content-between">
                          <span>{this.state.ticketStatusText}</span>
                          {this.state.ticketStatus == 8 &&
                          (
                            <span>{this.state.ticketOldDetails[0].resolved_on}</span>
                          )}
                        </div>
                        <LoadingWrapper isLoading={this.state.statusLoader}>
                            {(this.state.statusBlocker == 0 && this.state.iCanEdit) &&
                            (
                              <div className="assign-group ulchekbox">
                                {this.state.status.map((stat, index)=>
                                  {
                                    return(
                                      <div className="d-flex justify-content-between status-items rbstyle ch-item">
                                          <Input id={stat.id} name="ticketStatus" 
                                          value={stat.id} type="radio" 
                                          checked= {this.state.ticketStatus == stat.id? 'checked': ''} 
                                          onChange={this.updateTicketStatus}
                                          />
                                          <Label for={stat.id} >{stat.status}</Label>
                                      </div>
                                    )
                                  })}
                                </div>
                            )}
                      </LoadingWrapper>
                        {/* <Label htmlFor="ticket-date-field" className="ticket-date-title">Status</Label>
                        <div className="ticket-text-status issue_dis_input  read-field  d-flex justify-content-between">
                          <span>{this.state.ticketStatusText}</span>
                          {this.state.ticketStatus == 8 &&
                          (
                            <span>{this.state.ticketOldDetails[0].resolved_on}</span>
                          )}
                        </div>
                        <LoadingWrapper isLoading={this.state.statusLoader}>
                            {(this.state.statusBlocker == 0 && this.state.iCanEdit) &&
                            (
                              <div className="assign-group ulchekbox">
                                {this.state.status.map((stat, index)=>
                                  {
                                    return(
                                      <div className="d-flex justify-content-between status-items rbstyle ch-item">
                                          <Input id={stat.id} name="ticketStatus" 
                                          value={stat.id} type="radio" 
                                          checked= {this.state.ticketStatus == stat.id? 'checked': ''} 
                                          onChange={this.updateTicketStatus}
                                          />
                                          <Label for={stat.id} >{stat.status}</Label>
                                      </div>
                                    )
                                  })}
                                </div>
                            )}
                      </LoadingWrapper> */}
                      <div className="row-1 post_date due_date_field">
                          <Label htmlFor="ticket-date-field" className="ticket-date-title">Due Date
                            <span className="optional-label"> (optional)</span>
                          </Label>
                            <DateTimePicker
                              minDate ={this.state.minDate}
                              calendarIcon={
                              <img
                              className="calender-icon"
                              src={CalenderIcon}
                              width="12"
                              height="12"
                              alt="CalenderIcon"
                              />
                              }
                              clearIcon={
                                <img
                                className="calender-icon"
                                src={ClearIcon}
                                width="20"
                                height="20"
                                alt="CalenderIcon"
                                />
                                }
                              name='ticketDate'
                              onChange={this.onTDuedateChange}
                              value={this.state.dueDate}
                              format="dd-MM-y"
                            />
                            
                        </div>

                      </div>

                </div>{/*  ticket_detail_left */}
              </Card>
              <Card className="ticket-sidebar-wrapper">
                  <div className="ticket_header_left">
                    <CardTitle tag="h1">Ticket Reminder</CardTitle>
                  </div>
                  <LoadingWrapper isLoading={this.state.reminderAddingProgress}></LoadingWrapper>
                     <div className="row-1 post_date reminder-wrapper">
                         {/* <Label htmlFor="ticket-date-field" className="ticket-date-title remind-at">Remind Me About This Ticket</Label> */}
                          <div className="reminder-options d-flex align-items-center">
                            <FormGroup check>
                             <Label check>
                              <Input type="radio" name="reminderOptions" className="first-item" value="1" onChange={this.setReminderTimeOptions.bind(this)}/>
                              1 hour 
                            </Label>
                            </FormGroup>
                            <FormGroup check>
                             <Label check>
                               <Input type="radio" name="reminderOptions" value="2" onChange={this.setReminderTimeOptions.bind(this)} />
                               2 hours
                              </Label>
                             </FormGroup>
                             <FormGroup check>
                             <Label check>
                              <Input type="radio" name="reminderOptions" value="3" onChange={this.setReminderTimeOptions.bind(this)} />
                              3 hours
                             </Label>
                             </FormGroup>
                          </div>
                          <div className="reminder-options d-flex align-items-center">
                          <FormGroup check>
                             <Label check>
                             <Input type="radio" className="first-item" name="reminderOptions" value="5" onChange={this.setReminderTimeOptions.bind(this)} />
                             5 hours
                             </Label>
                          </FormGroup>
                          <FormGroup check>
                             <Label check>
                             <Input type="radio"  name="reminderOptions" value="24" onChange={this.setReminderTimeOptions.bind(this)} />
                             1 day
                             </Label>
                          </FormGroup>
                          <FormGroup check>
                             <Label check>
                             <Input type="radio" name="reminderOptions" value="48" onChange={this.setReminderTimeOptions.bind(this)} />
                             2 days
                             </Label>
                          </FormGroup>
                          </div>
                          

                          <Datetime
                            dateFormat="DD-MM-YYYY"
                            value={this.state.reminderTime}
                            onChange={this.setReminderime.bind(this)}
                            className={this.state.reminderTimeError? 'field-error' : ''}
                          />


                          <div className="reminder-to-options d-flex justify-content-between">
                            <span className="d-flex">
                              <Input type="radio" name="toOptions" value="1"  checked={this.state.remindGroup == 1 && 'checked' } onChange={this.updateSelection.bind(this)} />
                              <Label>Remind Me</Label>
                            </span>
                            <span className="d-flex">
                              <Input type="radio" name="toOptions" value="2" checked={this.state.remindGroup == 2 && 'checked' } onChange={this.updateSelection.bind(this)} />
                              <Label>Remind Others</Label>
                            </span>
                            <span className="d-flex">
                              <Input type="radio" name="toOptions" value="3"  checked={this.state.remindGroup == 3 && 'checked' } onChange={this.updateSelection.bind(this)} />
                              <Label>Specific</Label>
                            </span>
                          </div>

                          {this.state.remindGroup==3 &&
                          (
                              <ReactTags
                              ref={this.reactTags}
                              tags={this.state.remindMembersSelected}
                              suggestions={this.state.remindMembers}
                              onDelete={this.onDeleteRemindees.bind(this)}
                              onAddition={this.onAdditionRemindees.bind(this)}
                              placeholderText="Add People" />
                          )}

                      <div className="conform"> <Button className="btn btn-green save-ticket add_cmd_btn" onClick={this.addReminder}>Add Reminder</Button></div>
                     </div>
                     
                     <div className="remove-pending-reminders">
                      <LoadingWrapper isLoading={this.state.reminderDeletionProgress}>
                       {this.state.reminderDeletionActive ?
                       (
                          <>
                            <span>{this.state.pendingRemindersDeleted}</span>
                          </>
                       ):
                       (
                        <div className="remove_inner_text">
                         <Label className="remove_note">Remove My Pending Reminders</Label>
                         <Button className="btn btn-green save-ticket add_cmd_btn" onClick={this.removePendingReminders}>Confirm</Button>
                     
                        </div>
                       )}
                       </LoadingWrapper>
                       </div>

                     {this.state.myPrivileges.includes('am_admin') &&
                     (
                        <>
                          <div className="pending-reminder-info">
                            Pending Reminders: {this.state.pending_reminders}
                          </div>
                          <div className="pending-reminder-info d-flex align-items-center">
                          <FormGroup check>
                             <Label check>
                             <Input type="checkbox" value="1" onClick={this.adminShowReminders} />
                             Show All Reminders
                             </Label>
                          </FormGroup>
                       
                          </div>
                        </>
                     )}
              </Card>
             

            </Col>

            <Col xs={12} sm={12} md={12} lg={12} xl={8}>
            <section id="ticket_details">
              <Card className="issue_chat_detail">
                <div className="ticket-info">
                    <div className="ticket-summary">
                      <div className="d-flex tick_detailhead">
                          
                          <div className="back_btns">
                            {this.state.iCanEdit &&
                            (
                              <Button className="btn btn-green deletbtn" onClick={this.triggerDeletion}><MaterialIcon icon="delete_outline" /> </Button>
                            )}
                            <Button className="btn btn-green add_ticket_btn" onClick={this.showTicketCreation}>Create New Ticket <MaterialIcon icon="add"/></Button>
                            <Button className="backbtn" onClick={this.showDashboard}><MaterialIcon icon="home"/></Button>
                            {/* <button className="backbtn ticket_list" onClick={this.showTicketList}></button> */}
                          </div>
                      </div>
                      <div className="cmd_viewbox">
                      <div className="ticket-meta d-flex justify-content-between">
                          <div className="comd_pop_head">
                            <div className="owner user_detail">
                            
                              <div className="user_pic">
                                <img src={urls.storageUrl+"storage/profile/image/"+this.state.ticketOldDetails[0].created_by+".jpg?"+this.state.randomNum}  alt="user image"/>
                              </div>
                                <div className="date_n_time">
                                 <h1>{this.state.ticketOldDetails[0].ticket_owner}</h1>
                                 <div className="d-flex">
                                  <span className="posted-on">
                                  {this.state.ticketOldDetails[0].posted_date}
                                  </span>
                                  <span className="posted-time">
                                      {this.state.ticketOldDetails[0].posted_time}
                                  </span>
                                  </div>
                                  

                                </div>
                            </div>
                            <div className="post-date-time">
                            
                            {this.state.detaileditAllowed &&
                            (<span className="edit-ticket-desc edit_btn" onClick={this.onEditTicket}><MaterialIcon icon="edit" /></span>)
                            }
                            </div>
                          </div>
                      </div>
                      <div className="ticket-summary-description edit-ticket-desc">
                      <h2>{this.state.ticketOldDetails[0].subject}</h2>
                        {this.state.editTicketFlag && this.state.editTicketId>0?
                        (
                          <>
                          
                          <ReactQuill value={this.state.editTicketDescription}
                            onChange={this.handleChangeTicketEditor}
                            className={this.state.detailError? 'field-error' : ''}
                            placeholder= "Ticket description..." 
                            theme="snow" />
                          {/* <div className="ticket-attachments clearfix">
                              <SimpleReactLightbox>
                                  <SRLWrapper>
                                    {(this.state.ticketImages || []).map(url => (
                                      <>
                                      
                                          {url.match(/\.([^\./\?]+)($|\?)/)[1] == 'jpg' || url.match(/\.([^\./\?]+)($|\?)/)[1] == 'jpeg'
                                          || url.match(/\.([^\./\?]+)($|\?)/)[1] == 'png' || url.match(/\.([^\./\?]+)($|\?)/)[1] == 'gif' ?
                                          (
                                            <a href={url} target="_blank">
                                              <img src={url} alt="..." />
                                            </a>
                                          ):
                                          (
                                            <a href={url} target="_blank" className="attachment-bg">
                                              <AttachmentIcon />
                                            </a>
                                          )}
                                        </>
                                    ))}
                                  </SRLWrapper>
                              </SimpleReactLightbox>
                            </div> */}

                            <div className="clearfix">
                                <TicketFileUpload myid={this.state.myId}
                                folderpath="task/commentattachments"
                                arrayname="commentAttachments"
                                storefiles={this.storeTicketFileNames}
                                />

                                {(this.state.ticketImages && this.state.ticketImages.length > 0 && !this.state.ticketAttachmentsChange) &&
                                (
                                  <div class="attachment-items d-flex justify-contents-between">
                                    <TicketLightboxPop
                                      attachments={this.state.ticketImages}
                                      deleteitem="yes"
                                      deletefiles={this.deleteTicketFileNames}
                                      arrayname={'commentAttachments'}
                                      listtype={1}
                                    />
                                  </div>
                                  )}
                              </div>

                            <div className="edit-act-buttons d-flex justify-content-end">
                              <Button className="edit-ticket-save btn btn-grey save-ticket" onClick={this.cancelTicketEdit}>Cancel</Button>
                              <Button className="edit-ticket-save btn btn-green save-ticket" onClick={this.onUpdateTicketDetails}>Update</Button>
                            </div>
                          </>
                        )
                        :
                        (
                          <>
                          <Linkify tagName="p" options={this.state.options}>{ ReactHtmlParser( this.stripslashes(this.state.ticketOldDetails[0].ticket_details))}</Linkify>
                          <div className="ticket-attachments clearfix">
                                <SimpleReactLightbox>
                                    <SRLWrapper>
                                      {(this.state.ticketImages || []).map(url => (
                                        <>
                                            {url.match(/\.([^\./\?]+)($|\?)/)[1] == 'jpg' || url.match(/\.([^\./\?]+)($|\?)/)[1] == 'jpeg'
                                            || url.match(/\.([^\./\?]+)($|\?)/)[1] == 'png' || url.match(/\.([^\./\?]+)($|\?)/)[1] == 'gif' ?
                                            (
                                              <a href={url} target="_blank">
                                                <img src={url} alt="..." />
                                              </a>
                                            ):
                                            (
                                              <a href={url} target="_blank" className="attachment-bg">
                                                <AttachmentIcon />
                                              </a>
                                            )}
                                          </>
                                      ))}
                                    </SRLWrapper>
                                </SimpleReactLightbox>
                              </div>
                          </>
                          )
                        }
                      </div>
                    </div>
                    </div>
                </div>
                {this.state.ticketComments.map((comment, index)=>
                {
                    return(
                      <>
                      {comment.type!=2 ?
                      (
                        <TicketComment comment ={comment} 
                        showeditoption={this.state.editTicketCommentFlag}
                        editid={this.state.editTicketCommentId} 
                        edittext={this.state.editTicketComment} 
                        showcommentedit={this.showCommentEdit}
                        myid={this.state.myId}
                        commentupdate={this.updateTicketComment} 
                        cancelcommentupdate={this.cancelCommentUpdate}/>
                      ):
                      (
                        <>
                        {this.state.adminShowReminders ?
                        (
                          <TicketComment comment ={comment} 
                            showeditoption={this.state.editTicketCommentFlag}
                            editid={this.state.editTicketCommentId} 
                            edittext={this.state.editTicketComment} 
                            showcommentedit={this.showCommentEdit}
                            myid={this.state.myId}
                            myname={this.state.myName}
                            mytimezone={this.state.myTimezone}
                            commentupdate={this.updateTicketComment} 
                            cancelcommentupdate={this.cancelCommentUpdate}
                            afterreminderupdate={this.afterReminderUpdate}
                          />
                        ):
                        (
                            <>
                              {(comment.commented_by === this.state.myId) &&
                              (
                                <TicketComment comment ={comment} 
                                  showeditoption={this.state.editTicketCommentFlag}
                                  editid={this.state.editTicketCommentId} 
                                  edittext={this.state.editTicketComment} 
                                  showcommentedit={this.showCommentEdit}
                                  myid={this.state.myId}
                                  myname={this.state.myName}
                                  mytimezone={this.state.myTimezone}
                                  commentupdate={this.updateTicketComment} 
                                  cancelcommentupdate={this.cancelCommentUpdate}
                                  afterreminderupdate={this.afterReminderUpdate}
                                />
                              )}
                            </>
                        )}
                        
                        </>
                      )}
                    </>
                    );
                })}
              {(!this.state.editTicketFlag  && !this.state.editTicketCommentFlag ) && (
                  <>
                    <LoadingWrapper isLoading={this.state.commentAddingLoader}>
                      <div className="new-ticket-details">
                          <ReactQuill value={this.state.comments}
                          onChange={this.handleChangeEditor}
                          className={this.state.detailError? 'field-error' : ''}
                          placeholder= "Enter your comments here..." 
                          theme="snow"
                          modules={{mention: this.mentionModule}} />
                          
                        {/* <div className="comment-preview">
                          <div className="preview-description" dangerouslySetInnerHTML={{ __html: this.state.comments }} />
                        </div> */}

                      <div id="" className="flex-fill equalHM replay_upload">
                        <div className="form-group multi-preview">
                                {(this.state.imgagePreviews || []).map(url => (
                                  <div className="prv_imgs">
                                      <a href={url} target="_blank">
                                        <img src={url} alt="..." />
                                      </a>
                                      <span className="remove-img" onClick={this.removePreviewImg.bind(this, url)}></span>
                                  </div>
                                ))}
                        </div>
                        {/* <div className="file-wrapper upload-btn-wrapper">
                          <Input multiple type="file" name="attachments" id="attachments" onChange={this.uploadMultipleFiles} ></Input>
                          <Button className="btn_uplod">Attach Files</Button>
                        </div> */}


                      <TicketFileUpload myid={this.state.myId}
                        folderpath="task/commentattachments"
                        arrayname="commentAttachments"
                        storefiles={this.storeCommentFileNames}
                      />
                      {(this.state.commentAttachments && this.state.commentAttachments.length > 0 && !this.state.commentAttachmentsChange) &&
                      (
                        <div class="attachment-items d-flex justify-contents-between">
                          <TicketLightboxPop
                            attachments={this.state.commentAttachments}
                            deleteitem="yes"
                            deletefiles={this.deleteCommentFileNames}
                            arrayname={'commentAttachments'}
                            listtype={1}
                          />
                        </div>
                      )}

                      </div>
                      {/* {this.state.fileTypeError &&
                      (
                        <div className="file-type-error">
                          Attachments should be under 2 MB size and in image or pdf format. 
                        </div>
                      )} */}
                      <div className="add-ticket">
                        <Button className="btn btn-green save-ticket add_cmd_btn" onClick={this.createTicketComment}>Add Comment</Button>
                      </div>

                      </div>
                      
                     
                    </LoadingWrapper>
                  </>
              )}
              </Card>
              </section> 
            </Col> 
            <>
           
            {this.state.showHealthData &&
              (
               
                <div className="health-datas">
                   
                  <div className="health-data-overlay" onClick={this.closeHealth}></div>
                  <div className="health-data-content">
                      <h5 className="text-center">Health Data</h5>
                      <span className="close-health" onClick={this.closeHealth}><MaterialIcon icon="close"/></span>
                      <LoadingWrapper isLoading={this.state.healthLoader}>
                      {this.state.healthData.map((item, index)=>
                      {
                        return(
                          <Card>
                            <Row>
                              <Col sm="2">
                                {item.name}
                              </Col>
                              <Col sm="2">
                                {item.shift_date}
                              </Col>
                              <Col sm="3">
                                <StarRatings
                                  rating={item.rating}
                                  starRatedColor="orange"
                                  numberOfStars={5}
                                  starSpacing="3px"
                                  starDimension="30px"
                                  starEmptyColor="#e4dddd"
                                  starHoverColor="orange"
                                  isSelectable={false}
                                  />
                              </Col>
                              <Col sm="5">
                                {item.comments!= null &&
                                (
                                  <Linkify tagName="p" options={this.state.options}>{ ReactHtmlParser( this.stripslashes(item.comments))}</Linkify>
                                )}
                              </Col>
                            </Row>
                          </Card>
                        )
                      })}
                      </LoadingWrapper>
                  </div>
         
                </div>
               
              )}
            
          </>             
            {this.state.showDeletionDialogue ? (
            <ConfirmAction
              purpose="change"
              closeDialogue={this.closeDialogue}
              show={this.state.showDeletionDialogue}
              note="*This action cannot be undone, are you sure?"
              content="You are about to delete this ticket permanently."
              handleCompletionConfirm={this.handleDeletionConfirm}
              resolveid=""
              resolveStatus=""
              adminid=""
              confirmBoxAction="markasdeleted"
            />
            ) : null
          }
          </Row>
        ):
         null}
       </LoadingWrapper>
         
        </div>
      </Row>
      </Container>
 
      </div>
    )
  }
}
