// TicketList.js
import React, { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { withRouter } from 'react-router-dom';
import {
  CardBody,
  Table,
  Badge,
  Button,
  Input,
  FormGroup,
  Label,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
  Alert
} from 'reactstrap';
import MaterialIcon from 'material-icons-react';
import CreateTicketDrawer from './CreateTicketDrawer';
import { useGetsupporttickets } from './useHrTicketsHooks';
import{formatDate, getPriorityBadgeColor, getStatusBadgeColor} from './HelperFunctions';

const TicketList = ({ history,  assets}) => {
  const queryClient = useQueryClient();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [topics, setTopics] = useState(assets?.topics || []);
  const [priorities, setPriorities] = useState(assets?.priority || []);
  const [users, setUsers] = useState(assets?.assignees || []);
  const [filters, setFilters] = useState({
    status: 'active',
    topic: '',
    priority: '',
    assignee: '',
    search: '',
    dateRange: '7days'
  });

  // Main tickets query
  const { data: tickets, isLoading, error } = useGetsupporttickets(filters);


  // Mutations
  const closeTicketMutation = useMutation(
    async (ticketId) => {
      const response = await fetch(`/api/tickets/${ticketId}/close`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ status: 1 }),
      });
      if (!response.ok) throw new Error('Failed to close ticket');
      return response.json();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['tickets']);
      },
    }
  );

  const reopenTicketMutation = useMutation(
    async (ticketId) => {
      const response = await fetch(`/api/tickets/${ticketId}/reopen`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ status: 0 }),
      });
      if (!response.ok) throw new Error('Failed to reopen ticket');
      return response.json();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['tickets']);
      },
    }
  );

  // Helper functions
 

  // Action handlers
  const handleTicketEdit = (ticketId) => {
    history.push(`/tickets/${ticketId}/edit`);
  };

  const handleTicketClose = async (ticketId) => {
    try {
      await closeTicketMutation.mutateAsync(ticketId);
    } catch (error) {
      console.error('Error closing ticket:', error);
    }
  };

  const handleTicketReopen = async (ticketId) => {
    try {
      await reopenTicketMutation.mutateAsync(ticketId);
    } catch (error) {
      console.error('Error reopening ticket:', error);
    }
  };
  return (
    <div className="ticket-list-container">
      <div className="ticket-list-header">
        <Row className="align-items-center mb-3">
          <Col>
            <h3 className="mb-0 custom-page-title">Support Tickets</h3>
          </Col>
          <Col xs="auto">
            <Button color="primary btn-custom-primary btn-new-support" onClick={() => setIsDrawerOpen(true)}>
              <MaterialIcon icon="add" /> New Ticket
            </Button>
          </Col>
        </Row>

        {/* Filters Section */}
        {/* ... your existing filters section ... */}
      </div>

      <CardBody className='support-ticket-list-body'>
        {error ? (
          <Alert color="danger">
            {error.message || 'Error loading tickets. Please try again later.'}
          </Alert>
        ) : isLoading ? (
          <div className="text-center py-5">
            <Spinner color="primary" />
          </div>
        ) : !tickets?.data.tickets.length ? (
          <div className="text-center py-5">
            <MaterialIcon icon="inbox" size={48} />
            <h5 className="mt-2">No tickets found</h5>
            <p className="text-muted">
             Create a new ticket
            </p>
          </div>
        ) : (
          <div className="table-responsive">
            <Table className="ticket-table" hover>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Topic</th>
                  <th>Priority</th>
                  <th>Assignee</th>
                  <th>Created</th>
                  <th>Due Date</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {tickets?.data.tickets.map((ticket) => (
                  <tr key={ticket.id} onClick={() => history.push(`/tickets/support-ticket/${ticket.id}`)}>
                    <td>
                      <div className="d-flex align-items-center">
                        {ticket.ticket_title}
                      </div>
                    </td>
                    <td>
                      {topics?.find(t => t.id === ticket.topic_id)?.topic_label}
                    </td>
                    <td>
                      <Badge color={getPriorityBadgeColor(ticket.priority_id)} className="priority-badge">
                        {priorities?.find(p => p.id === ticket.priority_id)?.priority_label}
                      </Badge>
                    </td>
                    <td>
                      {/* {users?.find(u => u.id === ticket.assignee_id)?.name} */}
                      {ticket.assignee}
                    </td>
                    <td>{formatDate(ticket.created_at)}</td>
                    <td>{formatDate(ticket.due_date)}</td>
                    <td>
                      <Badge color={getStatusBadgeColor(ticket.status)}>
                        {ticket.status === 1 ? 'Active' : 'Closed'}
                      </Badge>
                    </td>
                    <td>
                      <UncontrolledDropdown>
                        <DropdownToggle color="link" className="btn-icon">
                          <MaterialIcon icon="more_vert" />
                        </DropdownToggle>
                        <DropdownMenu right>
                          <DropdownItem onClick={() => history.push(`/tickets/support-ticket/${ticket.id}`)}>
                            <MaterialIcon icon="visibility" /> View Details
                          </DropdownItem>
                          <DropdownItem 
                            onClick={() => handleTicketEdit(ticket.id)}
                            disabled={closeTicketMutation.isLoading || reopenTicketMutation.isLoading}
                          >
                            <MaterialIcon icon="edit" /> Edit
                          </DropdownItem>
                          {ticket.status === 0 && (
                            <DropdownItem 
                              onClick={() => handleTicketClose(ticket.id)}
                              disabled={closeTicketMutation.isLoading}
                            >
                              {closeTicketMutation.isLoading ? (
                                <Spinner size="sm" />
                              ) : (
                                <MaterialIcon icon="check_circle" />
                              )}{' '}
                              Mark as Closed
                            </DropdownItem>
                          )}
                          {ticket.status === 1 && (
                            <DropdownItem 
                              onClick={() => handleTicketReopen(ticket.id)}
                              disabled={reopenTicketMutation.isLoading}
                            >
                              {reopenTicketMutation.isLoading ? (
                                <Spinner size="sm" />
                              ) : (
                                <MaterialIcon icon="refresh" />
                              )}{' '}
                              Reopen
                            </DropdownItem>
                          )}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
      </CardBody>
      {assets && <CreateTicketDrawer isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} assets={assets} />}
    </div>
  );
};

export default withRouter(TicketList);