
import React, { useState } from 'react';
import { format, isToday, isYesterday } from 'date-fns';

export const formatDate = (date) => {
    const dateObj = new Date(date);
    if (isToday(dateObj)) {
      return `Today ${format(dateObj, 'HH:mm')}`;
    }
    if (isYesterday(dateObj)) {
      return `Yesterday ${format(dateObj, 'HH:mm')}`;
    }
    return format(dateObj, 'MMM dd, yyyy HH:mm');
  };

export const getPriorityBadgeColor = (priorityId) => {
    switch (priorityId) {
      case 1: return 'danger';    // High
      case 2: return 'warning';   // Medium
      case 3: return 'info';      // Low
      default: return 'secondary';
    }
  };

export const getStatusBadgeColor = (status) => {
    switch (status) {
      case 0: return 'success';   // Active
      case 1: return 'secondary'; // Closed
      default: return 'light';
    }
  };