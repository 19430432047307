import React, { useState, useEffect } from "react";
import { X } from "lucide-react";
import { Button } from "reactstrap";
import TextareaAutosize from "react-autosize-textarea";
import MaterialIcon from "material-icons-react";
import {
  useAddProfileReport,
  useGetUserFeedbackData,
  useAddUpdateSkipRating
} from "./useProfileReportHooks";

const FeedbackDrawer = ({
  isOpen,
  onClose,
  employeeName,
  userID,
  currentRatingCount,
  skippedRating
}) => {
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [ratingNeeded, setRatingNeeded] = useState(false);
  const [commentNeeded, setCommentNeeded] = useState(false);
  const [isQuestionVisible, setIsQuestionVisible] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [editID, setEditID] = useState(0);
  const [metric_types, setMetricTypes] = useState([]);
  const [skipRating, setSkipRating] = useState(skippedRating);
  const [skipRatingStatus, setSkipRatingStatus  ] = useState(skippedRating == 1 ? true : false);

  const { isLoading: reportLoading, data: reportsData } =
    useGetUserFeedbackData(userID);
  useEffect(() => {
    if (reportsData?.data.feedback && 
      reportsData?.data.feedback?.length > 0 &&
      reportsData?.data.questions[0]?.id ===
        reportsData.data.feedback[0]?.question_type_id
    ) {
      setRating(reportsData.data.feedback[0].rating);
      setComment(reportsData.data.feedback[0].rating_feedback);
      setEditID(reportsData.data.feedback[0].id);
      setSkipRating(reportsData.data.skip_feedback);
      if(reportsData.data.skip_feedback == 1){
        setSkipRatingStatus(true);
      }
    }
    setMetricTypes(reportsData?.data.questions);
  }, [reportsData]);

  const handleNextQuestion = (nextIndex) => {
    setIsQuestionVisible(false);
    setTimeout(() => {
      if (nextIndex < metric_types.length) {
        setRating(
          reportsData.data.feedback[nextIndex]?.rating
            ? reportsData.data.feedback[nextIndex].rating
            : 0
        );
        setComment(
          reportsData.data.feedback[nextIndex]?.rating_feedback
            ? reportsData.data.feedback[nextIndex].rating_feedback
            : ""
        );
        setEditID(
          reportsData.data.feedback[nextIndex]?.id > 0
            ? reportsData.data.feedback[nextIndex].id
            : 0
        );
        setTimeout(() => {
          setIsQuestionVisible(true);
        }, 50);
      } else {
        onClose();
      }
    }, 500);
  };

  const addProfileReport = (e) => {
    e.preventDefault();
    let nextIndex = currentQuestionIndex + 1;
    if (currentRatingCount === 6) {
      handleNextQuestion(nextIndex);
      setCurrentQuestionIndex(nextIndex);
    } else {
      setRatingNeeded(false);
      setCommentNeeded(false);
      if (rating <= 0) {
        setRatingNeeded(true);
        return;
      }
      if (comment.trim() === "") {
        setCommentNeeded(true);
        return;
      }
      setIsSaving(true);
      const data1 = {
        user_id: userID,
        rating: rating,
        comment: comment,
        question_type_id: metric_types[currentQuestionIndex].id,
        editID: editID,
      };
      addReportRating(data1, {
        onSuccess: (data) => {
          setCurrentQuestionIndex(nextIndex);
          handleNextQuestion(nextIndex);
        },
      });
    }
  };

  const { mutate: addReportRating, isLoading: addLoading } =
    useAddProfileReport();

  useEffect(() => {
    if (isOpen) {
      setCurrentQuestionIndex(0);
      setRating(0);
      setComment("");
      setIsQuestionVisible(true);
    }
  }, [isOpen]);

  const addUpdateSkipRating = () => {
    const data1 = {
      user_id: userID,
      skip_rating: skipRating,
    };
    updateSkipRating(data1, {
      onSuccess: (data) => {
        if(skipRating == 1){
          setSkipRatingStatus(true);
          onClose();
        }else
        {
          setSkipRatingStatus(false);
        }
      },
    });
  }

  const { mutate: updateSkipRating, isLoading: skipRatingLoading } =
  useAddUpdateSkipRating();


  return (
    <>
      <div
        className={`drawer-overlay ${isOpen ? "show" : ""}`}
        onClick={onClose}
      />

      <div
        className={`performace-feedback-drawer drawer ${isOpen ? "show" : ""}`}
      >
        <div className="border-bottom p-3 d-flex justify-content-between align-items-center darwerHeader">
          <h5
            className="mb-0"
            style={{
              color: "#212529",
              fontSize: "16px",
              fontWeight: "600",
            }}
          >
            Monthly Performance Metrics
          </h5>
          <button
            className="btn btn-link p-0"
            style={{
              width: "32px",
              height: "32px",

              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              textDecoration: "none",
            }}
            onClick={onClose}
          >
            <MaterialIcon icon="close" color="#667085" />
          </button>
        </div>
        {metric_types?.length > 0 ? (
          <>
            {addLoading || !isQuestionVisible || reportLoading || skipRatingLoading ? (
              <div className="d-flex justify-content-center align-items-center text-center mt-5 saving-feedback">
                <div
                  className="spinner-border text-success"
                  role="status"
                ></div>
                <span className="visually-hidden">
                  {isSaving ? "Saving your feedback" : "Please wait"}
                </span>
              </div>
            ) : (
              <div
                className={`fade-transition pt-4 pr-4 pl-4 ${
                  isQuestionVisible ? "fade-in" : "fade-out"
                }`}
              >
                <div
                  className={`mb-4 ${
                    currentRatingCount === 6 && "readonly-container"
                  }`}
                >
                  <h6 className="perfomance-question">
                    How satisfied are you with {employeeName}'s{" "}
                    <span>{metric_types[currentQuestionIndex]?.name}</span>?
                  </h6>
                  <span className="question_exp">{metric_types[currentQuestionIndex]?.type_name_desc}</span>
                  {currentRatingCount !== 6 && (
                    <p className="text-muted rating-info mt-2">
                      Please rate on a scale of 1-10
                    </p>
                  )}
                  <div
                    className={`btn-group w-100 ${
                      ratingNeeded && "required-field-half"
                    }`}
                    style={{
                      boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                    }}
                  >
                    {[...Array(10)].map((_, i) => (
                      <button
                        key={i + 1}
                        outline={rating !== i + 1}
                        onClick={() => setRating(i + 1)}
                        className={`btn rating-btn ${
                          i + 1 === rating
                            ? "btn-active-rating"
                            : "btn-outline-secondary"
                        }`}
                      >
                        {i + 1}
                      </button>
                    ))}
                  </div>
                </div>

                <div className="mb-4">
                  <TextareaAutosize
                    title={currentRatingCount === 6 && "Editing not allowed"}
                    readOnly={currentRatingCount === 6 ? "readOnly" : ""}
                    className={`form-control ${
                      commentNeeded && "required-field"
                    }`}
                    rows={4}
                    placeholder="Please enter your feedback"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  />
                </div>
              </div>
            )}
            <div className={`d-flex justify-content-between p-4 performance-feedback-btns ${skipRatingStatus ? 'skip_rating_block' : ''}`}>
              <span className="text-muted">
                Questions {currentQuestionIndex<6?  currentQuestionIndex+ 1 : currentQuestionIndex}/6
              </span>
              <div>
                <Button
                  color="light"
                  onClick={onClose}
                  className="mr-2 cancel-feedback"
                >
                  Cancel
                </Button>
                <Button
                  color="success"
                  className="save-next-feedback"
                  onClick={addProfileReport}
                >
                  {currentQuestionIndex === metric_types.length - 1
                    ? "Submit"
                    : "Next Question"}
                </Button>
              </div>
            </div>
            <div className="skip-rating-outer-wrap pt-4 pr-4">
              <div className="d-flex align-items-top skip-rating-wrap">
                <input
                  type="checkbox"
                  id="skipRating"
                  className="mr-2 mt-1"
                  checked={skipRating == 1 ? "checked" : ''}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSkipRating(1);
                    } else {
                      setSkipRating(0);
                    }
                  }}
                />
                  <label htmlFor="skipRating" className="mb-0" style={{fontWeight:'500'}}>This month I won't be rating this member, since I haven't worked with them much.</label>
              </div>
              <div className="d-flex justify-content-end">
                <Button
                  color="warning"
                  className="save-next-feedback"
                  onClick={addUpdateSkipRating}
                >Update
                </Button>
              </div>
            </div>
          </>
        ) : (
          <>
            {metric_types?.length === 0 && !reportLoading ? (
              <div className="d-flex justify-content-center align-items-center text-center mt-5 saving-feedback">
                <span className="visually-hidden">Sorry this role is not assigned with any performance evaluation questions yet.</span>
              </div>
            ):
            (
              <div className="d-flex justify-content-center align-items-center text-center mt-5 saving-feedback">
                <div className="spinner-border text-success" role="status"></div>
                <span className="visually-hidden">Please wait</span>
              </div>
            )}
          </>
          
        )}
      </div>
    </>
  );
};

export default FeedbackDrawer;
